import { useState } from "react";

const useToggle = () => {
  const [toggleValue, setToggleValue] = useState<boolean>(false);

  const toggle = () => {
    setToggleValue((prev) => !prev);
  };

  return { toggleValue, toggle };
};

export default useToggle;
