import DefaultTemplate from "@/templates/DefaultTemplate";
import {
  ContactUsHeadContent,
  ContactUsHeadSymbol,
  ContactUsHeadWrapper,
  ContactUsWrapper,
  ContactUsHeadTitle,
  ContactUsSection,
  ContactUsFormWrapper,
  ContactUsFormColumn,
  ContactUsInfoColumn,
  ContactUsInfoText,
  ContactUsInfoImage,
  ContactUsFormRow,
  ContactUsFormLabel,
  ContactUsFormInput,
  ContactUsFormTextArea,
  ContactUsCheckWrapper,
  ContactUsCheckText,
  ContactUsFormButtonWrapper,
} from "./ContactUs.stylest";
import Layout from "../common/Layout";
import CheckBox from "../common/Checkbox";
import Button from "../common/Button";
import {useState} from "react";
import axios from "axios";


const ContactUs = () => {
  const [email, setEmail] = useState('');
  const [title, setTitle] = useState('');
  const [contents, setContents] = useState('');
  const [checked, setChecked] = useState(false);

  const changeInput = (e: any) => {
    switch (e.target.name) {
      case "email" :
        setEmail(e.target.value);
        break;
      case "title" :
        setTitle(e.target.value);
        break;
      case "contents":
        setContents(e.target.value);
        break;
      default:
        break;
    }
  }

  const sendEmail = () => {
    axios({
      method: 'post',
      url   : '/ntes/v1/sendmailcontactus',
      data  : {
        email  : `${email}`,
        subject: `${title}`,
        body   : `send: [${email}] \n contents: ${contents}`
      }
    }).then(data => {
      alert('Thank you, your mail has been sent normally.');
    }).catch((error) => {
      console.log(error);
    })
  }

  return (
    <DefaultTemplate>
      <ContactUsWrapper>
        <ContactUsHeadWrapper>
          <ContactUsHeadContent>
            <ContactUsHeadSymbol src="/assets/images/contact-us/main-symbol.svg"/>
            <ContactUsHeadTitle>Contact us</ContactUsHeadTitle>
          </ContactUsHeadContent>
        </ContactUsHeadWrapper>
        <ContactUsSection>
          <Layout.Wrapper>
            <ContactUsFormWrapper>
              <ContactUsFormColumn>
                <ContactUsFormRow>
                  <ContactUsFormLabel>Email</ContactUsFormLabel>
                  <ContactUsFormInput type="email" name="email" placeholder="E-mail" onChange={(e) => {
                    changeInput(e)
                  }}/>
                </ContactUsFormRow>
                <ContactUsFormRow>
                  <ContactUsFormLabel>Subject</ContactUsFormLabel>
                  <ContactUsFormInput type="text" placeholder="Subject" name="title" onChange={(e) => {
                    changeInput(e)
                  }}/>
                </ContactUsFormRow>
                <ContactUsFormRow>
                  <ContactUsFormLabel>Specific Inquiries</ContactUsFormLabel>
                  <ContactUsFormTextArea name="contents"
                                         placeholder="How may we assist you? Our representative will get back to you promptly."
                                         onChange={(e) => {
                                           changeInput(e)
                                         }}/>
                </ContactUsFormRow>
                <ContactUsFormRow>
                  <ContactUsCheckWrapper>
                    <CheckBox propFunction={(check: boolean) => setChecked(check)}/>
                    <ContactUsCheckText>
                      I agree to receive marketing communications and the
                      collection of personal information.
                    </ContactUsCheckText>
                  </ContactUsCheckWrapper>
                </ContactUsFormRow>
                <ContactUsFormButtonWrapper>
                  <Button color="fill-aqua-white-500"
                          onClick={() => sendEmail()}
                          disabled={!checked || !email || !title || !contents}>Submit</Button>
                </ContactUsFormButtonWrapper>
              </ContactUsFormColumn>
              <ContactUsInfoColumn>
                <ContactUsInfoText>
                  We welcome inquiries regarding collaboration, partnerships,
                  media, and more related to the SIZN3 platform.
                </ContactUsInfoText>
                <ContactUsInfoImage src="/assets/images/contact-us/contact-us-info-image.png"/>
              </ContactUsInfoColumn>
            </ContactUsFormWrapper>
          </Layout.Wrapper>
        </ContactUsSection>
      </ContactUsWrapper>
    </DefaultTemplate>
  );
};

export default ContactUs;
