import { Routes, Route } from "react-router-dom";
import ResetStyles from "@/styles/reset.css";
import GlobalStyles from "@/styles/golobal.css";
import HomePage from "./pages/home";
import UseCasesPage from "./pages/use-cases";
import NaxionPage from "./pages/naxion";
import ContactUsPage from "./pages/contact-us";

function App() {
  return (
    <>
      <ResetStyles />
      <GlobalStyles />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/use-cases" element={<UseCasesPage />} />
        <Route path="/naxion" element={<NaxionPage />} />
        <Route path="/contact-us" element={<ContactUsPage />} />
      </Routes>
    </>
  );
}

export default App;
