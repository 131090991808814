import DefaultTemplate from "@/templates/DefaultTemplate";
import {
  HomeHeadBgImage,
  HomeHeadContentContainer,
  HomeHeadContentWrapper,
  HomeHeadText,
  HomeHeadTitle,
  HomeHeadWrapper,
  HomeBeltImage,
  HomeWrapper,
  HomeWhatIsSymbol,
  HomeWhatIsContent,
  HomeWhatIsText,
  HomeWhyContent,
  HomeWhyContentColumn,
  HomeWhyContentRow,
  HomeWhyContentIcon,
  HomeWhyContentTitle,
  HomeWhyContentText,
  HomeCompetitiveContent,
  HomeCompetitiveContentRow,
  HomeCompetitiveContentImage,
  HomeCompetitiveContentColumn,
  HomeCompetitiveContentTitle,
  HomeCompetitiveContentText,
  HomeHowContent,
  HomeHowContentImage,
  HomeHowContentColumn,
  HomeHowContentRow,
  HomeHowContentTitle,
  HomeHowContentText,
  HomeUseCasesContent,
  HomeUseCasesContentRow,
  HomeUseCasesContentColumn,
  HomeUseCasesBox,
  HomeUseCasesBoxTitle,
  HomeUseCasesBoxText,
  HomeVirtuousContent,
  HomeVirtuousImage,
  HomeFeaturesContent,
  HomeSection,
  HomeCompetitiveContentImageWrapper,
  HomeHowContentImageWrapper,
  MobileBR,
  HomeFeaturesSection, HomeButtonWrapper, HomeHowContentButton,
} from "./Home.styles";
import Button from "../common/Button";
import Layout from "../common/Layout";
import SectionTitle from "../common/SectionTitle";
import SlideList from "./SlideList";
import Icon from "@/components/common/Icon";

const Home = () => {
  return (
    <DefaultTemplate>
      <HomeWrapper>
        <HomeHeadWrapper>
          <HomeHeadBgImage src="/assets/images/home/home-header-symbol2.png"/>
          <HomeHeadContentContainer>
            <HomeHeadContentWrapper>
              <HomeHeadTitle/>
              <HomeHeadText>
                A Tokenomics Platform
                <br/>
                That Innovates the next Generation Industry
              </HomeHeadText>
              <HomeButtonWrapper>
              <Button size="small" color="outline-white"
                      onClick={() => window.open("https://sizn3.com/dashboard", "_blank")}>
                START FOR FREE
              </Button>
              </HomeButtonWrapper>

              <HomeButtonWrapper>
              <Button size="small" color="outline-white"
                      onClick={() => window.open("https://daoda.gitbook.io/sizn3-api-docs/", "_blank")}>
                API DOCs <Icon icon="ArrowOutline"/>
              </Button>
              </HomeButtonWrapper>
            </HomeHeadContentWrapper>
          </HomeHeadContentContainer>
        </HomeHeadWrapper>
        <HomeSection className="gray-bg">
          <Layout.Wrapper>
            <SectionTitle>WHAT IS SINZ3?</SectionTitle>
            <HomeWhatIsContent>
              <HomeWhatIsSymbol src="/assets/images/home/home-symbol.svg"/>
              <HomeWhatIsText>
                <b>SIZN3</b> is a Tokenomics Platform
                <br/>
                that supports the transition of your business to WEB3.0.
                <br/>
                SIZN3 can be utilized in various fields such as gaming,
                e-commerce,
                <br/>
                content, healthcare, etc., providing innovative assistance in
                improving marketing efficiency,
                <br/>
                increasing customer community, and enhancing profitability.
              </HomeWhatIsText>
            </HomeWhatIsContent>
          </Layout.Wrapper>
        </HomeSection>
        <HomeBeltImage src="/assets/images/home/home-belt.svg"/>
        <HomeSection className="gray-bg">
          <Layout.Wrapper>
            <SectionTitle>WHY SIZN3 TOKENOMICS?</SectionTitle>
            <HomeWhyContent>
              <HomeWhyContentRow>
                <HomeWhyContentColumn>
                  <HomeWhyContentIcon icon="Clock"/>
                  <HomeWhyContentTitle>
                    Easy and fast
                    <br/>
                    implementation in any service
                  </HomeWhyContentTitle>
                  <HomeWhyContentText>
                    With simple design and API integration, you can easily and
                    quickly apply Tokenomics to your customers in a familiar yet
                    convenient manner without significant changes to your
                    existing service structure.
                  </HomeWhyContentText>
                </HomeWhyContentColumn>
                <HomeWhyContentColumn>
                  <HomeWhyContentIcon icon="LightBulb"/>
                  <HomeWhyContentTitle>
                    Expansion of customer
                  </HomeWhyContentTitle>
                  <HomeWhyContentText>
                    Through transparent and direct token rewards, you can
                    continuously maintain meaningful customer actions and
                    participation. Customers will become a reliable support
                    community for you through a long-term and systematic reward
                    process, rather than just receiving one-time benefits.
                  </HomeWhyContentText>
                </HomeWhyContentColumn>
              </HomeWhyContentRow>
              <HomeWhyContentRow>
                <HomeWhyContentColumn>
                  <HomeWhyContentIcon icon="Gear"/>
                  <HomeWhyContentTitle>
                    Easy project management
                  </HomeWhyContentTitle>
                  <HomeWhyContentText>
                    Focus on project management with an efficiently organized
                    dashboard. Monitoring users and managing tokens are possible
                    for every project. SIZN3 provides automatic support for node
                    management and system administration, which can be complex
                    and tedious.
                  </HomeWhyContentText>
                </HomeWhyContentColumn>
                <HomeWhyContentColumn>
                  <HomeWhyContentIcon icon="GlobeStand"/>
                  <HomeWhyContentTitle>
                    World-class level of stability, Support for decentralized
                    networks!
                  </HomeWhyContentTitle>
                  <HomeWhyContentText>
                    SIZN3 operates in real-time on the public Layer 1 blockchain
                    Naxion network, ensuring global top-level stability,
                    transparency, and decentralization.
                  </HomeWhyContentText>
                </HomeWhyContentColumn>
              </HomeWhyContentRow>
            </HomeWhyContent>
          </Layout.Wrapper>
        </HomeSection>
        <HomeSection>
          <Layout.Wrapper>
            <SectionTitle>COMPETITIVE ADVANTAGE</SectionTitle>
            <HomeCompetitiveContent>
              <HomeCompetitiveContentRow reverse>
                <HomeCompetitiveContentImageWrapper>
                  <HomeCompetitiveContentImage src="/assets/images/home/home-competitive-image-01.png"/>
                </HomeCompetitiveContentImageWrapper>
                <HomeCompetitiveContentColumn>
                  <HomeCompetitiveContentTitle>
                    Integrated API service for the development of WEB3.0
                    Tokenomics
                  </HomeCompetitiveContentTitle>
                  <HomeCompetitiveContentText>
                    SIZN3 provides an API that allows businesses aiming to
                    implement Tokenomics to easily and quickly adapt without
                    being influenced by the system environments of each service.
                  </HomeCompetitiveContentText>
                </HomeCompetitiveContentColumn>
              </HomeCompetitiveContentRow>
              <HomeCompetitiveContentRow>
                <HomeCompetitiveContentImageWrapper>
                  <HomeCompetitiveContentImage src="/assets/images/home/home-competitive-image-02.png"/>
                </HomeCompetitiveContentImageWrapper>
                <HomeCompetitiveContentColumn>
                  <HomeCompetitiveContentTitle>
                    No charges for initial implementation!
                  </HomeCompetitiveContentTitle>
                  <HomeCompetitiveContentText>
                    The surprising fact is that there is zero cost for
                    implementing SIZN3 Tokenomics into ongoing projects or
                    businesses. Additionally, customer reward tokens during
                    platform operation can be utilized on a pay-as-you-go basis,
                    allowing for flexible configuration and execution according
                    to budget constraints.
                  </HomeCompetitiveContentText>
                </HomeCompetitiveContentColumn>
              </HomeCompetitiveContentRow>
              <HomeCompetitiveContentRow reverse>
                <HomeCompetitiveContentImageWrapper>
                  <HomeCompetitiveContentImage src="/assets/images/home/home-competitive-image-03.png"/>
                </HomeCompetitiveContentImageWrapper>
                <HomeCompetitiveContentColumn>
                  <HomeCompetitiveContentTitle>
                    Stable service design applicable to large-scale deployments
                  </HomeCompetitiveContentTitle>
                  <HomeCompetitiveContentText>
                    MSA (Micro Service Architecture) is applied to provide
                    optimized services in a stable manner, supporting services
                    ranging from small-scale to large-scale.
                  </HomeCompetitiveContentText>
                </HomeCompetitiveContentColumn>
              </HomeCompetitiveContentRow>
              <HomeCompetitiveContentRow>
                <HomeCompetitiveContentImageWrapper>
                  <HomeCompetitiveContentImage src="/assets/images/home/home-competitive-image-04.png"/>
                </HomeCompetitiveContentImageWrapper>
                <HomeCompetitiveContentColumn>
                  <HomeCompetitiveContentTitle>
                    Real-time on-chain system
                  </HomeCompetitiveContentTitle>
                  <HomeCompetitiveContentText>
                    By supporting real-time on-chain capabilities during service
                    operations, SIZN3 eliminates the fundamental issues of
                    hacking and tampering in the service’s Tokenomics.
                  </HomeCompetitiveContentText>
                </HomeCompetitiveContentColumn>
              </HomeCompetitiveContentRow>
            </HomeCompetitiveContent>
          </Layout.Wrapper>
        </HomeSection>
        <HomeSection className="black-bg">
          <Layout.Wrapper>
            <SectionTitle color="outline-white">HOW it WORKS</SectionTitle>
            <HomeHowContent>
              <HomeHowContentRow>
                <HomeHowContentImageWrapper>
                  <HomeHowContentImage src="/assets/images/home/home-how-image-01.png"/>
                </HomeHowContentImageWrapper>
                <HomeHowContentColumn>
                  <HomeHowContentTitle>
                    Leave the complex process of node management to SIZN3!
                  </HomeHowContentTitle>
                  <HomeHowContentText>
                    Try creating and managing projects specific to your business
                    on the User Dashboard! You can also monitor the players
                    within the Token Economy that you have built.
                  </HomeHowContentText>


                  <HomeHowContentButton>
                    <Button size="xsmall" color="outline-white"
                            onClick={() => window.open("https://daoda.gitbook.io/sizn3-user-guide/introduction/overview", "_blank")}>
                      DASHBOARD MANUAL <Icon icon="ArrowOutline"/>
                    </Button>
                  </HomeHowContentButton>

                </HomeHowContentColumn>
              </HomeHowContentRow>
              <HomeHowContentRow reverse>
                <HomeHowContentImageWrapper>
                  <HomeHowContentImage src="/assets/images/home/home-how-image-02.png"/>
                </HomeHowContentImageWrapper>
                <HomeHowContentColumn>
                  <HomeHowContentTitle>
                    You can easily and quickly apply Token Economy to each
                    project.
                  </HomeHowContentTitle>
                  <HomeHowContentText>
                    Through developer-friendly APIs, you can apply Token Economy
                    to your projects quickly and easily.
                  </HomeHowContentText>

                  <HomeHowContentButton>
                    <Button size="xsmall" color="outline-white"
                            onClick={() => window.open("https://daoda.gitbook.io/sizn3-api-docs/", "_blank")}>
                      API DOCs <Icon icon="ArrowOutline"/>
                    </Button>
                  </HomeHowContentButton>
                </HomeHowContentColumn>
              </HomeHowContentRow>
            </HomeHowContent>
          </Layout.Wrapper>
        </HomeSection>
        <HomeFeaturesSection className="gray-bg">
          <Layout.Wrapper maxWidth={1440}>
            <SectionTitle>FEATURES</SectionTitle>
            <HomeFeaturesContent>
              <SlideList
                list={[
                  {
                    imgSrc: "/assets/images/home/features-1.png",
                    text  : "Create multi-signature wallets",
                  },
                  {
                    imgSrc: "/assets/images/home/features-2.png",
                    text  : "List wallet balances and transactions",
                  },
                  {
                    imgSrc: "/assets/images/home/features-3.png",
                    text  : "Create and sign transactions",
                  },
                  {
                    imgSrc: "/assets/images/home/features-4.png",
                    text  : "Monitor transactions and\n receive notifications",
                  },
                  {
                    imgSrc: "/assets/images/home/features-5.png",
                    text  : "Securely authenticate users",
                  },
                  {
                    imgSrc: "/assets/images/home/features-6.png",
                    text  : "Run multi-user workflows in\n enterprise environments",
                  },
                  {
                    imgSrc: "/assets/images/home/features-7.png",
                    text  : "Apply policies and spending\n limits",
                  },
                ]}
              />
            </HomeFeaturesContent>
          </Layout.Wrapper>
        </HomeFeaturesSection>
        <HomeSection>
          <Layout.Wrapper>
            <SectionTitle>USE CASES</SectionTitle>
            <HomeUseCasesContent>
              <HomeUseCasesContentRow>
                <HomeUseCasesContentColumn>
                  <HomeUseCasesBox className="outline-aqua">
                    <HomeUseCasesBoxTitle>Gaming</HomeUseCasesBoxTitle>
                    <HomeUseCasesBoxText>
                      Consider offering exclusive token-based items or benefits
                      in your game! This not only provides revenue streams for
                      game developers but also offers players a new gaming
                      experience.
                    </HomeUseCasesBoxText>
                  </HomeUseCasesBox>
                  <HomeUseCasesBox className="fill-black">
                    <HomeUseCasesBoxTitle>E-commerce</HomeUseCasesBoxTitle>
                    <HomeUseCasesBoxText>
                      You can create an innovative token-based incentive program
                      to reward customer loyalty and encourage user
                      participation.
                    </HomeUseCasesBoxText>
                  </HomeUseCasesBox>
                </HomeUseCasesContentColumn>
                <HomeUseCasesContentColumn>
                  <HomeUseCasesBox className="fill-aqua">
                    <HomeUseCasesBoxTitle>Content</HomeUseCasesBoxTitle>
                    <HomeUseCasesBoxText>
                      Customers can receive rewards for consuming, recommending,
                      and sharing enjoyable content, while businesses can gain
                      insights into the content that customers truly desire and
                      naturally attract new customers
                    </HomeUseCasesBoxText>
                  </HomeUseCasesBox>
                </HomeUseCasesContentColumn>
              </HomeUseCasesContentRow>
              <HomeUseCasesContentRow>
                <HomeUseCasesContentColumn>
                  <HomeUseCasesBox className="fill-aqua">
                    <HomeUseCasesBoxTitle>Healthcare</HomeUseCasesBoxTitle>
                    <HomeUseCasesBoxText>
                      Healthcare service users can receive rewards for the
                      positive changes they experience while using the service,
                      motivating them to engage in health management
                      consistently and enjoyably.
                    </HomeUseCasesBoxText>
                  </HomeUseCasesBox>
                </HomeUseCasesContentColumn>
                <HomeUseCasesContentColumn>
                  <HomeUseCasesBox className="fill-black">
                    <HomeUseCasesBoxTitle>Sustainability</HomeUseCasesBoxTitle>
                    <HomeUseCasesBoxText>
                      We support customers to actively participate in saving the
                      planet by proposing and rewarding their efforts to reduce
                      carbon emissions in their daily lives.
                    </HomeUseCasesBoxText>
                  </HomeUseCasesBox>
                  <HomeUseCasesBox className="outline-aqua">
                    <HomeUseCasesBoxTitle>SCM business</HomeUseCasesBoxTitle>
                    <HomeUseCasesBoxText>
                      By implementing SIZN3 in supply chain management, you can
                      facilitate transparent and traceable transactions,
                      ensuring the authenticity and origin of products while
                      reducing the risk of counterfeiting.
                    </HomeUseCasesBoxText>
                  </HomeUseCasesBox>
                </HomeUseCasesContentColumn>
              </HomeUseCasesContentRow>
            </HomeUseCasesContent>
          </Layout.Wrapper>
        </HomeSection>
        <HomeSection className="gray-bg">
          <Layout.Wrapper>
            <SectionTitle>
              THE VIRTUOUS
              <MobileBR/> CIRCLE OF SIZN3
            </SectionTitle>
            <HomeVirtuousContent>
              <HomeVirtuousImage src="/assets/images/home/home-virtuous-image.svg"/>
            </HomeVirtuousContent>
          </Layout.Wrapper>
        </HomeSection>
      </HomeWrapper>
    </DefaultTemplate>
  );
};

export default Home;
