import DefaultTemplate from "@/templates/DefaultTemplate";
import styled, {css} from "styled-components";
import Typography from "../common/Typography";
import Layout from "../common/Layout";

import {ReactComponent as SINZ3} from "@/assets/images/SIZN3.svg";
import Icon from "../common/Icon";
import media from "@/theme/media";
import {convertObjectToCss} from "@/utils/common/styles";

export const HomeWrapper = styled.div``;
export const HomeHeadWrapper = styled(DefaultTemplate.HeaderOffset).attrs({
  as: "section",
})`
  ${({theme}) => css`
    position: relative;
    background: ${theme.color.aqua[500]};
    height: 100vh;
    max-height: 1080px;

    ${media.mobile`
    ${css`
      height: unset;
    `}
    `}
  `}
`;

export const HomeHeadBgImage = styled.img`
  max-width: 600px;
  min-width: 460px;
  width: 100%;
  position: absolute;
  right: 120px;
  bottom: 0;
  padding-left: 16px;
  ${media.tablet`
  ${css`
    max-width: 350px;
    min-width: 350px;
    right: 0px;
  `}
`}
  ${media.mobile`
    ${css`
    max-width: 300px;
    min-width: 300px;
    right: -60px;
  `}
    `}
`;

export const HomeHeadContentContainer = styled(Layout.Container)`
  position: relative;
  padding-top: 160px;
  padding-bottom: 160px;

  ${media.mobile`
    ${css`
    padding-top: 180px;
    padding-bottom: 212px;
  `}
  `}
`;
export const HomeHeadContentWrapper = styled(Layout.Wrapper)`
  ${({theme}) => css`
    color: ${theme.color.white};
  `}
`;
export const HomeHeadTitle = styled(SINZ3)`
  width: 384px;
  margin-bottom: 32px;

  ${media.mobile`
    ${css`
    width: 192px;
    margin-bottom: 0;
  `}
  `}
`;

export const HomeHeadText = styled(Typography).attrs({
  variant: "body2",
})`
  margin-bottom: 42px;

  ${({theme}) => css`
    ${media.mobile`
    ${css`
      ${convertObjectToCss(theme.typography.body7)};
      margin-bottom: 20px;
    `}
  `}
  `}
`;

export const HomeButtonWrapper = styled.div`
  margin-right: 40px;
  display: inline-block;

  > button svg {
    fill: white;
    margin-left: 8px;
  }

  &:hover {
    > button svg {
      fill: #10bebf;
    }
  }

  ${media.mobile`
    ${css`
    margin-top: 10px;
  `}
  `}
`;

export const HomeBeltImage = styled.img`
  width: 100%;
  object-fit: cover;
  min-height: 120px;
`;

export const HomeSection = styled(Layout.Section)`
  text-align: center;

  ${({theme}) => css`
    &.gray-bg {
      background: ${theme.color.gray[50]};
    }

    &.black-bg {
      background: ${theme.color.black};
    }
  `}
`;

export const HomeWhatIsContent = styled.div`
  margin-top: 78px;

  ${media.mobile`
    ${css`
    margin-top: 40px;
  `}
  `}
`;

export const HomeWhatIsSymbol = styled.img`
  width: 85px;
  margin-bottom: 40px;

  ${media.mobile`
    ${css`
    width: 62px;
    margin-bottom: 20px;
  `}
  `}
`;

export const HomeWhatIsText = styled(Typography).attrs({
  variant: "body5",
})`
  ${({theme}) => css`
    color: ${theme.color.gray[700]};
    font-weight: ${theme.font.fontWeight.regular};

    & b {
      font-weight: ${theme.font.fontWeight.bold};
    }

    ${media.mobile`
    ${css`
      ${convertObjectToCss(theme.typography.body8)};
    `}
  `}
  `}
`;

export const HomeWhyContent = styled.div`
  text-align: left;
  margin-top: 138px;

  ${media.mobile`
    ${css`
    margin-top: 76px;
  `}
  `}
`;

export const HomeWhyContentRow = styled.div`
  display: flex;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 100px;
  }

  ${media.mobile`
    ${css`
    flex-direction: column;
    gap: 35px;

    &:not(:last-child) {
      margin-bottom: 35px;
    }
  `}
  `}
`;
export const HomeWhyContentColumn = styled.div`
  max-width: 522px;
  width: 100%;

  ${media.mobile`
    ${css`
    max-width: unset;
  `}
  `}
`;

export const HomeWhyContentIcon = styled(Icon)`
  width: 60px;
  height: 60px;
  margin-bottom: 20px;

  ${media.mobile`
    ${css`
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
  `}
  `}
`;

export const HomeWhyContentTitle = styled(Typography).attrs({
  variant: "body4",
})`
  margin-bottom: 28px;
  ${({theme}) => css`
    color: ${theme.color.black};

    ${media.mobile`
    ${css`
      ${convertObjectToCss(theme.typography.body8)};
      font-weight: ${theme.font.fontWeight.semiBold};

      margin-bottom: 14px;
    `}
    `}
  `}
`;

export const HomeWhyContentText = styled(Typography).attrs({
  variant: "body7",
})`
  ${({theme}) => css`
    ${media.mobile`
    ${css`
      ${convertObjectToCss(theme.typography.body9)};
    `}
    `}
  `}
`;

export const HomeCompetitiveContent = styled.div`
  text-align: left;
  margin-top: 138px;

  ${media.mobile`
    ${css`
    margin-top: 82px;
  `}
  `}
`;

export const HomeCompetitiveContentRow = styled.div<{ reverse?: boolean }>`
  display: flex;
  justify-content: space-between;
  gap: 40px;

  &:not(:last-child) {
    margin-bottom: 120px;
  }

  ${({reverse}) =>
          reverse &&
          css`
            flex-direction: row-reverse;
          `}

  ${media.mobile`
    ${css`
    flex-direction: column;
    gap: 0;

    &:not(:last-child) {
      margin-bottom: 52px;
    }
  `}
  `}
`;

export const HomeCompetitiveContentImageWrapper = styled.div`
  flex-shrink: 0;
  height: 294px;
  width: 413px;
  background: #10bebf;
  border-radius: 30px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.mobile`
    ${css`
    width: 100%;
    height: 244px;
  `}
  `}
`;
export const HomeCompetitiveContentImage = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
export const HomeCompetitiveContentColumn = styled.div`
  max-width: 658px;
`;
export const HomeCompetitiveContentTitle = styled(Typography).attrs({
  variant: "body4",
})`
  margin: 40px 0 30px;

  ${({theme}) => css`
    ${media.mobile`
    ${css`
      margin: 20px 0 15px;
      ${convertObjectToCss(theme.typography.body8)};
      font-weight: ${theme.font.fontWeight.semiBold};
    `}
  `}
  `}
`;
export const HomeCompetitiveContentText = styled(Typography).attrs({
  variant: "body7",
})`
  ${({theme}) => css`
    ${media.mobile`
    ${css`
      ${convertObjectToCss(theme.typography.body9)};
    `}
  `}
  `}
`;

export const HomeHowContent = styled.div`
  text-align: left;
  margin-top: 138px;

  ${media.mobile`
    ${css`
    margin-top: 54px;
  `}
  `}
`;

export const HomeHowContentRow = styled.div<{ reverse?: boolean }>`
  display: flex;
  gap: 60px;

  &:not(:last-child) {
    margin-bottom: 200px;
  }

  ${({reverse}) =>
          reverse &&
          css`
            flex-direction: row-reverse;
          `}

  ${media.mobile`
    ${css`
    gap: 30px;
    flex-direction: column;

    &:not(:last-child) {
      margin-bottom: 52px;
    }
  `}
  `}
`;

export const HomeHowContentImageWrapper = styled.div``;
export const HomeHowContentImage = styled.img`
  max-width: 738px;
  width: 100%;

  ${media.mobile`
    ${css`
    max-width: unset;
  `}
  `}
`;

export const HomeHowContentColumn = styled.div`
  max-width: 482px;
  width: 100%;

  ${media.mobile`
    ${css`
    max-width: unset;
  `}
  `}
`;

export const HomeHowContentTitle = styled(Typography).attrs({
  variant: "body4",
})`
  margin-bottom: 32px;
  ${({theme}) => css`
    color: ${theme.color.white};

    ${media.mobile`
    ${css`
      margin-bottom: 16px;
      ${convertObjectToCss(theme.typography.body8)};
      font-size: ${theme.font.fontWeight.semiBold};
    `}
    `}
  `}
`;
export const HomeHowContentText = styled(Typography).attrs({
  variant: "body7",
})`
  ${({theme}) => css`
    color: ${theme.color.gray[500]};

    ${media.mobile`
    ${css`
      margin-bottom: 16px;
      ${convertObjectToCss(theme.typography.body9)};
    `}
    `}
  `}
`;

export const HomeHowContentButton = styled.div`
  margin-top: 20px;

  > button svg {
    fill: white;
    margin-left: 8px;
  }

  &:hover {
    > button svg {
      fill: #10bebf;
    }
  }
`;

export const HomeFeaturesSection = styled(HomeSection)`
  overflow: hidden;

  @media screen and (max-width: 1024px) {
    padding-right: 0;
  }
`;
export const HomeFeaturesContent = styled.div`
  margin-top: 98px;

  ${media.mobile`
    ${css`
    margin-top: 49px;
  `}
  `}
`;

export const HomeUseCasesContent = styled.div`
  text-align: left;
  margin-top: 98px;

  ${media.mobile`
    ${css`
    margin-top: 40px;
  `}
  `}
`;

export const HomeUseCasesContentRow = styled.div`
  display: flex;
  gap: 10px;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
  ${media.mobile`
    ${css`
    gap: 8px;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  `}
  `}
`;

export const HomeUseCasesContentColumn = styled.div`
  display: flex;
  width: 50%;
  gap: 10px;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
  ${media.mobile`
    ${css`
    gap: 8px;
  `}
  `}
`;

export const HomeUseCasesBox = styled.div`
  width: 100%;
  border-radius: 60px;
  padding: 40px;
  ${({theme}) => css`
    &.outline-aqua {
      border: 1px solid ${theme.color.aqua[500]};
      color: ${theme.color.aqua[500]};
    }

    &.fill-black {
      background: ${theme.color.gray[800]};
      color: ${theme.color.white};
    }

    &.fill-aqua {
      background: ${theme.color.aqua[500]};
      color: ${theme.color.white};
    }
  `}

  ${media.mobile`
    ${css`
    border-radius: 30px;
    padding: 20px;
  `}
  `}
`;

export const HomeUseCasesBoxTitle = styled(Typography).attrs({
  variant: "body3",
})`
  ${({theme}) => css`
    margin-bottom: 30px;
    ${media.mobile`
    ${css`
      margin-bottom: 15px;
      ${convertObjectToCss(theme.typography.body8)};
      font-weight: ${theme.font.fontWeight.semiBold};
    `}
  `}
  `}
`;

export const HomeUseCasesBoxText = styled(Typography).attrs({
  variant: "body7",
})`
  ${({theme}) => css`
    ${media.mobile`
    ${css`
      ${convertObjectToCss(theme.typography.body9)};
    `}
  `}
  `}
`;

export const HomeVirtuousContent = styled.div`
  margin-top: 98px;

  ${media.mobile`
    ${css`
    margin-top: 50px;
  `}
  `}
`;

export const HomeVirtuousImage = styled.img`
  max-width: 990px;
  width: 100%;
  margin: 0 auto;
`;

export const MobileBR = styled.br`
  display: none;
  ${media.mobile`
    ${css`
    display: block;
  `}
  `}
`;
