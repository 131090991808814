import styled, { css } from "styled-components";
import Typography from "../../common/Typography";
import media from "@/theme/media";
import { convertObjectToCss } from "@/utils/common/styles";

export const SlideListWrapper = styled.div`
  position: relative;

  & .slide-list-inner {
    overflow: hidden;
    padding-bottom: 60px;

    ${media.mobile`
    ${css`
      padding-bottom: 30px;
    `}
    `}
  }
  & .swiper {
    overflow: visible;
  }
  & .swiper-pagination {
    top: unset;
    bottom: -60px;

    ${media.mobile`
    ${css`
      bottom: -30px;
    `}
    `}
  }

  ${({ theme }) => css`
    & .swiper-pagination-progressbar {
      position: relative;
      width: 100%;
      background: transparent;
      @media screen and (max-width: 1024px) {
        width: calc(100% - 16px);
      }
    }
    & .swiper-pagination-progressbar:before {
      content: "";
      display: inline-block;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      height: 1px;
      background: ${theme.color.gray[300]};
    }

    & .swiper-pagination-progressbar-fill {
      background: ${theme.color.aqua[500]};
    }

    & .slide-navigation {
      position: absolute;
      top: 32%;
    }
    & .slide-prev {
      left: -60px;
    }
    & .slide-next {
      right: -60px;
    }
  `}
`;

export const SlideImage = styled.img`
  margin-bottom: 30px;
  width: 100%;
  border-radius: 30px;
  object-fit: cover;

  ${media.mobile`
    ${css`
      margin-bottom: 15px;
    `}
    `}
`;

export const SlideText = styled(Typography).attrs({
  variant: "body6",
})`
  white-space: pre-wrap;

  ${({ theme }) => css`
    ${media.mobile`
    ${css`
      ${convertObjectToCss(theme.typography.body9)};
      font-weight: ${theme.font.fontWeight.semiBold};
    `}
    `}
  `}
`;
