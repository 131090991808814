import DefaultTemplate from "@/templates/DefaultTemplate";
import styled, { css } from "styled-components";
import Layout from "../common/Layout";
import Typography from "../common/Typography";
import media from "@/theme/media";
import { convertObjectToCss } from "@/utils/common/styles";

export const UseCasesWrapper = styled.div``;

export const UseCasesHeadWrapper = styled(DefaultTemplate.HeaderOffset).attrs({
  as: "section",
})`
  position: relative;
  height: 100vh;
  max-height: 1080px;
  background: url("/assets/images/use-cases/use-cases-head-background.png")
    no-repeat center;
  background-size: cover;

  ${media.mobile`
    ${css`
      height: unset;
    `}
  `}
`;

export const UseCasesHeadDimmed = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  ${({ theme }) => css`
    background: ${theme.color.black};
    opacity: 0.5;
  `}
`;

export const UseCasesHeadContentContainer = styled(Layout.Container)`
  position: relative;
  padding-top: 358px;
  padding-bottom: 358px;

  ${media.mobile`
    ${css`
      padding-top: 228px;
      padding-bottom: 228px;
    `}
  `}
`;

export const UseCasesHeadContentWrapper = styled(Layout.Wrapper)`
  ${({ theme }) => css`
    color: ${theme.color.white};
  `}
`;

export const UseCasesHeadContentTitle = styled(Typography).attrs({
  variant: "subtitle1",
})`
  ${({ theme }) => css`
    ${media.mobile`
    ${css`
      ${convertObjectToCss(theme.typography.body5)};
      font-weight: ${theme.font.fontWeight.black};
    `}
    `}
  `}
`;

export const UseCasesHeadContentText = styled(Typography).attrs({
  variant: "body2",
})`
  margin-bottom: 42px;

  ${({ theme }) => css`
    ${media.mobile`
    ${css`
      ${convertObjectToCss(theme.typography.body8)};
      font-weight: ${theme.font.fontWeight.semiBold};
      margin-bottom: 20px;
    `}
    `}
  `}
`;

export const UseCasesInfoContainer = styled(Layout.Section)`
  ${({ theme }) => css`
    &.gray-bg {
      background: ${theme.color.gray[50]};
    }

    ${media.mobile`
    ${css`
      padding: 58px 16px;
    `}
    `}
  `}
`;

type UseCasesInfoWrapperProps = {
  reverse?: boolean;
};
export const UseCasesInfoWrapper = styled(
  Layout.Wrapper
)<UseCasesInfoWrapperProps>`
  display: flex;
  justify-content: space-between;
  gap: 60px;

  ${({ reverse }) =>
    reverse &&
    css`
      flex-direction: row-reverse;
    `}

  ${media.mobile`
    ${css`
      flex-direction: column;
      gap: 16px;
    `}
    `}
`;
export const UseCasesInfoImageWrapper = styled.div`
  width: 100%;
`;
export const UseCasesInfoImage = styled.img`
  width: 100%;
  display: block;
`;

export const UseCasesInfoContent = styled.div`
  max-width: 482px;
  width: 100%;
`;

export const UseCasesInfoTitle = styled(Typography).attrs({
  variant: "body3",
})`
  margin-bottom: 12px;

  ${({ theme }) => css`
    ${media.mobile`
    ${css`
      ${convertObjectToCss(theme.typography.body8)};
      font-weight: ${theme.font.fontWeight.semiBold};
    `}
    `}
  `}
`;
export const UseCasesInfoText = styled(Typography).attrs({
  variant: "body8",
})`
  margin-bottom: 32px;

  ${({ theme }) => css`
    ${media.mobile`
    ${css`
      margin-bottom: 16px;
      ${convertObjectToCss(theme.typography.body8)};
    `}
    `}
  `}
`;

export const UseCasesWebSection = styled(Layout.Section)`
  text-align: center;
  ${({ theme }) => css`
    background: ${theme.color.aqua[500]};
  `}
  overflow: hidden;

  @media screen and (max-width: 1024px) {
    padding-right: 0;
  }
`;

export const UseCasesWebDescription = styled(Typography).attrs({
  variant: "body8",
})`
  ${({ theme }) => css`
    color: ${theme.color.white};
    max-width: 848px;
    margin: 0 auto;
    margin-top: 24px;

    ${media.mobile`
    ${css`
      ${convertObjectToCss(theme.typography.body9)};
      margin-top: 12px;
    `}
    `}
  `}
`;

export const UseCasesWebContent = styled.div`
  margin-top: 98px;

  ${media.mobile`
    ${css`
      margin-top: 49px;
    `}
  `}
`;
