import {useEffect, useRef} from "react";
import {
  HeaderContainer,
  HeaderWrapper,
  HeaderLogo,
  HeaderNav,
  HeaderNavLinkText,
  HeaderSideWrapper,
  // HeaderGloberButton,
  HeaderGetStartedButton,
  // GloberMenuWrapper,
  // GloberMenuItem,
  // GloberMenuItemLabel,
  // HeaderGloberButtonWrapper,
} from "./Header.styles";
import {Link} from "react-router-dom";

import {ReactComponent as MainLogoImage} from "@/assets/images/main-logo.svg";
// import Icon from "@/components/common/Icon";
// import useToggle from "@/hooks/common/useToggle";
import useMobile from "@/hooks/common/useIsMobile";
import MobileHeader from "../MobileHeader";

export const LinkList = [
  {title: "NAXION", path: "/naxion", target: ""},
  {title: "USE CASES", path: "/use-cases", target: ""},
  {title: "API DOCS", path: "https://daoda.gitbook.io/sizn3-api-docs/", target: "_blank"},
  {title: "CONTACT US", path: "/contact-us", target: ""},
];

const Header = () => {
  const {isMobile} = useMobile();
  return <>{!isMobile ? <DeskTopHeader/> : <MobileHeader/>}</>;
};

export default Header;

const DeskTopHeader = () => {
  const headerRef = useRef<HTMLHeadElement>(null);

  // const {toggleValue, toggle} = useToggle();
  // const [selectedGlobe, setSelectedGlobe] = useState("English");

  useEffect(() => {
    const headerElement = headerRef.current;
    if (!headerElement) return;

    const headerObserver = document.createElement("div");
    headerElement.before(headerObserver);

    const observer = new IntersectionObserver(([entry]) => {
      headerElement.classList.toggle("with-background", !entry.isIntersecting);
    });
    observer.observe(headerObserver);

    return () => {
      observer.unobserve(headerObserver);
    };
  }, []);

  return (
    <HeaderContainer as={"header"} ref={headerRef}>
      <HeaderWrapper>
        <HeaderLogo>
          <Link to={"/"}>
            <MainLogoImage/>
          </Link>
        </HeaderLogo>
        <HeaderNav>
          {LinkList.map(({title, path, target}) => {
            return (
              <Link to={path} key={path} target={target}>
                <HeaderNavLinkText>{title}</HeaderNavLinkText>
              </Link>
            );
          })}
        </HeaderNav>
        <HeaderSideWrapper>
          <HeaderGetStartedButton
            onClick={() => window.open("https://sizn3.com/dashboard", "_blank")}>
            GET STARTED
          </HeaderGetStartedButton>
          {/*<HeaderGloberButtonWrapper>*/}
          {/*  <HeaderGloberButton*/}
          {/*    onClick={() => {*/}
          {/*      toggle();*/}
          {/*    }}*/}
          {/*  />*/}
          {/*  {toggleValue && (*/}
          {/*    <GloberMenu*/}
          {/*      selectedGlobe={selectedGlobe}*/}
          {/*      onClickMenu={(selected) => {*/}
          {/*        setSelectedGlobe(selected);*/}
          {/*        toggle();*/}
          {/*      }}*/}
          {/*    />*/}
          {/*  )}*/}
          {/*</HeaderGloberButtonWrapper>*/}
        </HeaderSideWrapper>
      </HeaderWrapper>
    </HeaderContainer>
  );
};
// type GloberMenuProps = {
//   selectedGlobe: string;
//   onClickMenu: (selected: string) => void;
// };
// const GloberMenu = ({selectedGlobe, onClickMenu}: GloberMenuProps) => {
//   const menuList = [{label: "English"}, {label: "한국어"}];
//   return (
//     <GloberMenuWrapper>
//       {menuList.map(({label}) => {
//         return (
//           <GloberMenuItem
//             className={selectedGlobe === label ? "active" : ""}
//             onClick={() => {
//               onClickMenu(label);
//             }}
//             key={label}
//           >
//             <GloberMenuItemLabel>{label}</GloberMenuItemLabel>
//             {selectedGlobe === label && <Icon icon="CircleCheck"/>}
//           </GloberMenuItem>
//         );
//       })}
//     </GloberMenuWrapper>
//   );
// };
