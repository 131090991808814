import DefaultTemplate from "@/templates/DefaultTemplate";
import styled, { css } from "styled-components";
import Layout from "../common/Layout";
import Typography from "../common/Typography";
import Button from "../common/Button";
import media from "@/theme/media";
import { convertObjectToCss } from "@/utils/common/styles";

export const NaxionWrapper = styled.div``;

export const NaxionHeadWrapper = styled(DefaultTemplate.HeaderOffset).attrs({
  as: "section",
})`
  ${({ theme }) => css`
    position: relative;
    background: ${theme.color.black};
    padding-top: 0;
    ${media.mobile`
    ${css`
      padding-top: 56px;
    `}
    `}
  `}
`;

export const NaxionHeaderVideoWrapper = styled.div`
  height: 100vh;
  max-height: 1530px;
  position: relative;

  ${media.mobile`
    ${css`
      max-height: 625px;
      padding-top: 56px;
    `}
    `}

  & iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const NaxionSection = styled(Layout.Section)`
  text-align: center;
`;

export const NaxionIntroductionSection = styled(NaxionSection)`
  padding: 180px 16px;
  ${({ theme }) => css`
    background: ${theme.color.black};
  `}

  ${media.mobile`
    ${css`
      padding: 90px 16px 120px;
    `}
  `}
`;

export const NaxionIntroductionContent = styled.div`
  display: flex;
  gap: 64px;
  align-items: center;
  text-align: left;
  margin-top: 74px;

  ${media.mobile`
    ${css`
      flex-direction: column;
      margin-top: 34px;
      gap: 36px;
    `}
  `}
`;
export const NaxionIntroductionImage = styled.img`
  width: 216px;

  ${media.mobile`
    ${css`
      width: 115px;
    `}
  `}
`;
export const NaxionIntroductionText = styled(Typography).attrs({
  variant: "body6",
})`
  ${({ theme }) => css`
    color: ${theme.color.white};

    ${media.mobile`
    ${css`
      ${convertObjectToCss(theme.typography.body8)};
      text-align: center;
    `}
    `}
  `}
`;

export const NaxionIntroductionButtonWrapper = styled.div`
  text-align: right;

  ${media.mobile`
    ${css`
      text-align: center;
      margin-top: 36px;
    `}
    `}
`;

export const NaxionNetworkContent = styled.div`
  display: flex;
  gap: 60px;
  text-align: left;
  margin-top: 118px;

  ${media.mobile`
    ${css`
      display: none;
    `}
  `}
`;

export const MobileNaxionNetworkContent = styled.div`
  display: none;
  text-align: left;

  ${media.mobile`
    ${css`
      display: flex;
      flex-direction: column;
      margin-top: 58px;
      gap: 30px;
    `}
  `}
`;

export const NaxionNetworkContentColumn = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 60px;

  &:last-child {
    margin-top: 190px;
  }

  ${media.mobile`
    ${css`
      width: 100%;
      gap: 30px;
      &:last-child {
        margin-top: 30px;
      }
    `}
  `}
`;

export const NaxionNetworkMobileContent = styled.div``;

export const NaxionNetworkCardBlur = styled.img`
  position: absolute;
`;
export const NaxionNetworkCard = styled.div`
  position: relative;
  width: 100%;
  padding: 30px;
  border-radius: 30px;
  min-height: 377px;
  ${({ theme }) => css`
    &.aqua {
      background: ${theme.color.aqua[500]};
    }

    &.gray {
      background: ${theme.color.gray[100]};
      border: 1px solid ${theme.color.aqua[500]};
    }

    &.white {
      background: ${theme.color.white};
      border: 1px solid ${theme.color.aqua[500]};
    }
  `}

  ${media.mobile`
    ${css`
      width: calc(100% - 55px);
      min-height: 354px;

      &:nth-child(even) {
        margin-right: 55px;
      }
      &:nth-child(odd) {
        margin-left: 55px;
      }
    `}
  `}
`;

export const NexionNetworkCardTitle = styled(Typography).attrs({
  variant: "body4",
})`
  margin-bottom: 20px;
  ${({ theme }) => css`
    .aqua & {
      color: ${theme.color.white};
    }

    .gray & {
      color: ${theme.color.aqua[500]};
    }

    .white & {
      color: ${theme.color.aqua[500]};
    }

    ${media.mobile`
    ${css`
      margin-bottom: 10px;
      ${convertObjectToCss(theme.typography.body8)};
      font-weight: ${theme.font.fontWeight.semiBold};
    `}
    `}
  `}
`;

export const NexionNetworkCardText = styled(Typography).attrs({
  variant: "body9",
})`
  ${({ theme }) => css`
    .aqua & {
      color: ${theme.color.white};
    }

    .gray & {
      color: ${theme.color.gray[700]};
    }

    .white & {
      color: ${theme.color.gray[700]};
    }

    ${media.mobile`
    ${css`
      ${convertObjectToCss(theme.typography.body9)};
    `}
    `}
  `}
`;

export const NaxionWhitePaperSection = styled(NaxionSection)`
  padding: 102px 16px;
  text-align: left;
  ${({ theme }) => css`
    background: ${theme.color.gray[50]};
  `}

  ${media.mobile`
    ${css`
      padding: 50px 16px;
    `}
    `}
`;

export const NaxionWhitePaperContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${media.mobile`
    ${css`
      flex-direction: column;
    `}
  `}
`;

export const NaxionWhitePaperTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;

  ${media.mobile`
    ${css`
      gap: 12px;
      margin-bottom: 36px;
    `}
  `}
`;

export const NaxionWhitePaperTitle = styled(Typography).attrs({
  variant: "subtitle3",
})`
  ${({ theme }) => css`
    ${media.mobile`
    ${css`
      ${convertObjectToCss(theme.typography.body7)};
      font-weight: ${theme.font.fontWeight.bold};
    `}
  `}
  `}
`;

export const NaxionWhitePaperImage = styled.img`
  ${media.mobile`
    ${css`
      width: 70px;
    `}
  `}
`;

export const NaxionWhitePaperButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  ${media.mobile`
    ${css`
      gap: 6px;
    `}
  `}
`;

export const NaxionWhitePaperButton = styled(Button).attrs({
  color: "fill-aqua-white-500",
})``;
