import { Link, useLocation, useMatch, useMatches } from "react-router-dom";
import {
  HeaderContainer,
  HeaderHamburgerMenu,
  HeaderLogo,
  HeaderWrapper,
  MobileHeaderNav,
  MobileHeaderContent,
  MobileHeaderNavItem,
  MobileHeaderNavItemLink,
  MobileHeaderNavText,
  MobileHeaderArrowButton,
  MobileHeaderGetStartedButton,
} from "./MobileHeader.styles";
import { ReactComponent as MainLogoImage } from "@/assets/images/main-logo.svg";
import useToggle from "@/hooks/common/useToggle";
import { useEffect } from "react";
import Icon from "@/components/common/Icon";
import { LinkList } from "../Header";

const MobileHeader = () => {
  const { toggleValue, toggle } = useToggle();

  const location = useLocation();

  useEffect(() => {
    if (toggleValue) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [toggleValue]);

  return (
    <>
      <HeaderContainer as={"header"}>
        <HeaderWrapper>
          <HeaderLogo>
            <Link to={"/"}>
              <MainLogoImage />
            </Link>
          </HeaderLogo>
          <HeaderHamburgerMenu
            icon={toggleValue ? "Close" : "Hamburger"}
            onClick={() => {
              toggle();
            }}
          />
        </HeaderWrapper>
      </HeaderContainer>
      {toggleValue && (
        <MobileHeaderContent>
          <MobileHeaderNav>
            {LinkList.map(({ title, path }) => {
              const isActive = location.pathname === path;
              return (
                <MobileHeaderNavItemLink
                  to={path}
                  key={path}
                  className={isActive ? "active" : ""}
                >
                  <MobileHeaderNavText>{title}</MobileHeaderNavText>
                  <MobileHeaderArrowButton />
                </MobileHeaderNavItemLink>
              );
            })}
            <MobileHeaderNavItem>
              <MobileHeaderNavText>
                <Icon icon="Glober" />
                ENG
              </MobileHeaderNavText>
            </MobileHeaderNavItem>
            <MobileHeaderNavItem>
              <MobileHeaderNavText>
                <Icon icon="Glober" />
                KR
              </MobileHeaderNavText>
            </MobileHeaderNavItem>
          </MobileHeaderNav>
          <MobileHeaderGetStartedButton
            onClick={() => window.open("https://sizn3.com/dashboard", "_blank")}>
            GET STARTED
          </MobileHeaderGetStartedButton>
        </MobileHeaderContent>
      )}
    </>
  );
};

export default MobileHeader;
