import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import { SlideImage, SlideListWrapper } from "./SlideList.styles";
import { Pagination } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import Layout from "../../common/Layout";
import { useRef } from "react";
import ArrowButton from "@/components/common/ArrowButton";

type SlideListProps = {
  list: {
    imgSrc: string;
  }[];
  onClickImage: (imgSrc: string) => void;
};
const SlideList = ({ list, onClickImage }: SlideListProps) => {
  const swiperRef = useRef<SwiperRef>(null);
  return (
    <SlideListWrapper>
      <Layout.Wrapper className="slide-list-inner">
        <Swiper
          spaceBetween={30}
          slidesPerView={1.25}
          pagination={{
            type: "progressbar",
          }}
          ref={swiperRef}
          onInit={(swiper) => {
            if (!swiper.destroyed) return;
          }}
          modules={[Pagination]}
          slidesOffsetAfter={16}
          breakpoints={{
            768: {
              slidesOffsetAfter: 16,
              slidesPerView: 1.25,
              spaceBetween: 30,
            },
            1024: {
              slidesOffsetAfter: 0,
              slidesPerView: 3,
              spaceBetween: 60,
            },
          }}
        >
          {list.map(({ imgSrc }, index) => (
            <SwiperSlide key={index}>
              <SlideImage
                src={imgSrc}
                onClick={() => {
                  onClickImage(imgSrc);
                }}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Layout.Wrapper>
      <ArrowButton
        color="white"
        className="slide-navigation slide-prev"
        size={36}
        direction="left"
        onClick={() => {
          swiperRef?.current?.swiper.slidePrev();
        }}
      />
      <ArrowButton
        color="white"
        className="slide-navigation slide-next"
        size={36}
        direction="right"
        onClick={() => {
          swiperRef?.current?.swiper.slideNext();
        }}
      />
    </SlideListWrapper>
  );
};

export default SlideList;
