import Layout from "@/components/common/Layout";
import Typography from "@/components/common/Typography";
import media from "@/theme/media";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const FooterContainer = styled(Layout.Container).attrs({ as: "footer" })`
  margin-top: auto;
  ${({ theme }) => css`
    background: ${theme.color.black};
  `}
`;

export const FooterWrapper = styled(Layout.Wrapper)`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 40px 0;

  ${media.mobile`
    ${css`
      flex-direction: column;
      padding: 20px 0 60px;
    `}
  `}
`;

export const FooterInfo = styled(Typography).attrs({
  variant: "body9",
})`
  ${({ theme }) => css`
    color: ${theme.color.gray[500]};
  `}
`;

export const FooterNav = styled.nav`
  display: flex;
  gap: 36px;
`;

export const FooterLink = styled(Link)`
  ${({ theme }) => css`
    color: ${theme.color.white};
  `}
`;
