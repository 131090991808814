import ArrowButton from "@/components/common/ArrowButton";
import IconButton from "@/components/common/IconButton";
import Layout from "@/components/common/Layout";
import Typography from "@/components/common/Typography";
import { convertObjectToCss } from "@/utils/common/styles";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const HeaderContainer = styled(Layout.Container)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;

  ${({ theme }) => css`
    background: ${theme.color.white};
    box-shadow: 0 4px 20px ${theme.color.shadow[0]};
  `}
`;

export const HeaderWrapper = styled(Layout.Wrapper)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 56px;
`;

export const HeaderLogo = styled.h1`
  svg {
    width: 102px;
    vertical-align: middle;
  }

  a {
    display: inline-block;
    vertical-align: middle;
  }

  ${({ theme }) => css`
    svg path {
      fill: ${theme.color.aqua[500]};
    }
  `}
`;

export const HeaderHamburgerMenu = styled(IconButton).attrs({
  size: 24,
})``;

export const MobileHeaderContent = styled.div`
  padding: 14px 16px;
  position: fixed;
  z-index: 8888;
  width: 100%;
  height: calc(100vh - 56px);
  top: 56px;
  left: 0;
  ${({ theme }) => css`
    background: ${theme.color.white};
  `}
`;

export const MobileHeaderNav = styled.nav``;

export const MobileHeaderNavItemLink = styled(Link)`
  display: flex;
  justify-content: space-between;
  padding: 26px 0;
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.color.gray[200]};
  `}
`;
export const MobileHeaderNavText = styled(Typography).attrs({
  variant: "body9",
})`
  display: flex;
  align-items: center;
  gap: 8px;

  & svg {
    width: 24px;
    height: 24px;
  }

  ${({ theme }) => css`
    .active & {
      color: ${theme.color.aqua[500]};
    }
  `}
`;
export const MobileHeaderNavItem = styled.button`
  display: flex;
  padding: 26px 0;
  width: 100%;
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.color.gray[200]};

    &:last-child {
      border-bottom: 0;
    }
  `}
`;

export const MobileHeaderArrowButton = styled(ArrowButton).attrs({
  color: "black",
  direction: "right",
  size: 24,
  disabled: true,
})`
  ${({ theme }) => css`
    .active & {
      background: ${theme.color.aqua[500]};
    }
  `}
`;

export const MobileHeaderGetStartedButton = styled.button`
  margin-top: 18px;

  ${({ theme }) => css`
    display: inline-flex;
    width: 100%;
    color: ${theme.color.white};
    background: ${theme.color.aqua[500]};
    height: 49px;
    align-items: center;
    justify-content: center;
    border-radius: 64px;
    ${convertObjectToCss(theme.typography.button4)};

    &:hover {
      background: ${theme.color.black};
      color: ${theme.color.aqua[500]};
    }
  `}
`;
