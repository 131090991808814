import {
  FooterContainer,
  FooterInfo,
  FooterLink,
  FooterNav,
  FooterWrapper,
} from "./Footer.styles";

const Footer = () => {
  return (
    <FooterContainer>
      <FooterWrapper>
        <FooterInfo>
          SIZN3 | Daoda Inc. Copyright All Right Reserved. E-mail:
          master@daoda.ai
        </FooterInfo>
        <FooterNav>
          <FooterLink to={"/assets/pdf/SIZN3_Terms_Eng_v1.0.pdf"} target={"_blank"}>TERMS</FooterLink>
          <FooterLink to={"/assets/pdf/SIZN3_Privacy_Eng_v1.0.pdf"} target={"_blank"}>PRIVACY</FooterLink>
          <FooterLink to={"/assets/pdf/SIZN3_cookies_v1.0.pdf"} target={"_blank"}>COOKIES</FooterLink>
        </FooterNav>
      </FooterWrapper>
    </FooterContainer>
  );
};

export default Footer;
