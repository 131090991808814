import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import { SlideImage, SlideListWrapper, SlideText } from "./SlideList.styles";
import { Pagination } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import IconButton from "../../common/IconButton";
import Layout from "../../common/Layout";
import { useRef } from "react";
import ArrowButton from "@/components/common/ArrowButton";

type SlideListProps = {
  list: {
    imgSrc: string;
    text: string;
  }[];
};
const SlideList = ({ list }: SlideListProps) => {
  const swiperRef = useRef<SwiperRef>(null);
  return (
    <SlideListWrapper>
      <Layout.Wrapper className="slide-list-inner">
        <Swiper
          spaceBetween={10}
          slidesPerView={2.25}
          pagination={{
            type: "progressbar",
          }}
          ref={swiperRef}
          onInit={(swiper) => {
            if (!swiper.destroyed) return;
          }}
          modules={[Pagination]}
          slidesOffsetAfter={16}
          breakpoints={{
            768: {
              slidesOffsetAfter: 16,
              slidesPerView: 2.25,
              spaceBetween: 10,
            },
            1024: {
              slidesOffsetAfter: 0,
              slidesPerView: 4,
              spaceBetween: 20,
            },
          }}
        >
          {list.map(({ imgSrc, text }, index) => (
            <SwiperSlide key={index}>
              <SlideImage src={imgSrc} />
              <SlideText>{text}</SlideText>
            </SwiperSlide>
          ))}
        </Swiper>
      </Layout.Wrapper>
      <ArrowButton
        className="slide-navigation slide-prev"
        direction="left"
        size={36}
        onClick={() => {
          swiperRef?.current?.swiper.slidePrev();
        }}
      />
      <ArrowButton
        className="slide-navigation slide-next"
        direction="right"
        size={36}
        onClick={() => {
          swiperRef?.current?.swiper.slideNext();
        }}
      />
    </SlideListWrapper>
  );
};

export default SlideList;
