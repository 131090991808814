import media from "@/theme/media";
import { convertObjectToCss } from "@/utils/common/styles";
import styled, { css } from "styled-components";

export type ButtonProps = {
  size?: "large" | "medium" | "small" | "xsmall";
  color?:
    | "fill-aqua-black-500"
    | "fill-aqua-white-500"
    | "fill-white"
    | "outline-white"
    | "font-white";
  fullWidth?: boolean;
};

const Button = styled.button<ButtonProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  ${({ color = "fill-aqua-black-500", theme }) => {
    switch (color) {
      case "fill-aqua-black-500":
        return css`
          color: ${theme.color.black};
          background-color: ${theme.color.aqua[500]};

          &:hover {
            color: ${theme.color.aqua[500]};
            background-color: ${theme.color.black};
          }
        `;
      case "fill-aqua-white-500":
        return css`
          color: ${theme.color.white};
          background-color: ${theme.color.aqua[500]};

          &:hover {
            color: ${theme.color.aqua[500]};
            background-color: ${theme.color.black};
          }
        `;
      case "fill-white":
        return css`
          color: ${theme.color.black};
          background-color: ${theme.color.white};

          &:hover {
            color: ${theme.color.black};
            background-color: ${theme.color.aqua[500]};
          }
        `;
      case "outline-white":
        return css`
          color: ${theme.color.white};
          border: 1px solid ${theme.color.white};

          &:hover {
            color: ${theme.color.aqua[500]};
            background-color: ${theme.color.white};
          }
        `;
      case "font-white":
        return css`
          color: ${theme.color.white};

          &:hover {
            color: ${theme.color.aqua[500]};
            background-color: ${theme.color.white};
          }
        `;
    }
  }}

  ${({ size = "medium", theme }) => {
    switch (size) {
      case "large":
        return css`
          height: 65px;
          padding: 0 38px;
          ${convertObjectToCss(theme.typography.button1)};
          border-width: 2px;

          border-radius: 64px;

          ${media.mobile`
            ${css`
              height: 41px;
              padding: 0 19px;
              ${convertObjectToCss(theme.typography.button3)};
            `}
          `}
        `;
      case "medium":
        return css`
          height: 54px;
          padding: 0 38px;
          ${convertObjectToCss(theme.typography.button2)};

          border-radius: 64px;

          ${media.mobile`
            ${css`
              height: 37px;
              padding: 0 19px;
              ${convertObjectToCss(theme.typography.body8)};
            `}
          `}
        `;
      case "small":
        return css`
          height: 41px;
          padding: 0 38px;
          ${convertObjectToCss(theme.typography.button3)};

          border-radius: 64px;

          ${media.mobile`
            ${css`
              height: 31px;
              padding: 0 20px;
              ${convertObjectToCss(theme.typography.body8)};
              font-weight: ${theme.font.fontWeight.light};
            `}
          `}
        `;
      case "xsmall":
        return css`
          height: 33px;
          padding: 0 18px;
          ${convertObjectToCss(theme.typography.button4)};

          border-radius: 64px;
        `;
    }
  }}


  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
  ${({ theme }) =>
    css`
      &:disabled {
        background: ${theme.color.gray[300]};
        color: ${theme.color.white};
      }
    `}
`;

export default Button;
