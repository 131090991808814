import Button from "@/components/common/Button";
import IconButton from "@/components/common/IconButton";
import Typography from "@/components/common/Typography";
import media from "@/theme/media";
import styled, { css } from "styled-components";
import Layout from "@/components/common/Layout";

export const HeaderContainer = styled(Layout.Container)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 8888;
  width: 100%;

  ${({ theme }) => css`
    &.with-background {
      background: ${theme.color.white};

      box-shadow: 0 4px 20px ${theme.color.shadow[0]};
    }
  `}
`;

export const HeaderWrapper = styled(Layout.Wrapper)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 70px;

  ${media.mobile`
    ${css`
      height: 56px;
    `}
  `}
`;

export const HeaderLogo = styled.h1`
  svg {
    width: 160px;
    height: 39px;
    vertical-align: middle;
  }

  a {
    display: inline-block;
    vertical-align: middle;
  }

  ${({ theme }) => css`
    svg path {
      fill: ${theme.color.white};
    }

    .with-background & {
      svg path {
        fill: ${theme.color.aqua[500]};
      }
    }
  `}

  @media screen and (max-width:1024px) {
    svg {
      width: 102px;
    }
  }
`;

export const HeaderNav = styled.nav`
  display: flex;
  gap: 50px;

  @media screen and (max-width: 1024px) {
    gap: 20px;
  }
`;

export const HeaderNavLinkText = styled(Typography).attrs({
  variant: "body8",
})`
  ${({ theme }) => css`
    color: ${theme.color.white};

    &:hover {
      color: ${theme.color.black};

      &:not(.with-background) {
        &:after {
          content: "";
          display: inline-block;
          position: absolute;
          padding: 2px 12px;
          left: 50%;
          top: 50%;
          z-index: -1;
          border-radius: 6px;
          background: #ffffff80;
          transform: translate(-50%, -50%);
          width: 100%;
          height: 100%;
        }
      }
    }

    .with-background & {
      color: ${theme.color.black};

      &:hover {
        color: ${theme.color.aqua[500]};
      }
    }
    position: relative;
  `}
`;

export const HeaderSideWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 34px;

  @media screen and (max-width: 1024px) {
    gap: 20px;
  }
`;

export const HeaderGetStartedButton = styled(Button).attrs({
  size: "xsmall",
  color: "outline-white",
})`
  ${({ theme }) => css`
    .with-background & {
      border-color: ${theme.color.aqua[500]};
      color: ${theme.color.aqua[500]};

      &:hover {
        background: ${theme.color.aqua[500]};
        color: ${theme.color.white};
        border: 1px solid transparent;
      }
    }
  `}
`;

export const HeaderGloberButtonWrapper = styled.div`
  position: relative;
`;
export const HeaderGloberButton = styled(IconButton).attrs({
  size: 28,
  icon: "Glober",
})`
  ${({ theme }) => css`
    svg {
      vertical-align: middle;
    }
    svg path {
      fill: ${theme.color.white};
    }

    .with-background & {
      svg path {
        fill: ${theme.color.black};
      }
    }
  `}
`;

export const GloberMenuWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 48px;
  white-space: nowrap;
  min-width: 160px;
  padding: 10px 0;

  ${({ theme }) => css`
    background: ${theme.color.white};
  `}
`;

export const GloberMenuItem = styled.button`
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  ${({ theme }) => css`
    &.active {
      color: ${theme.color.aqua[500]};
    }
  `}
`;

export const GloberMenuItemLabel = styled(Typography).attrs({
  variant: "body9",
})``;
