import DefaultTemplate from "@/templates/DefaultTemplate";
import {
  NaxionHeadWrapper,
  NaxionIntroductionButtonWrapper,
  NaxionIntroductionContent,
  NaxionIntroductionImage,
  NaxionIntroductionSection,
  NaxionIntroductionText,
  NaxionNetworkCard,
  NaxionNetworkContentColumn,
  NaxionNetworkContent,
  NaxionSection,
  NaxionWhitePaperButton,
  NaxionWhitePaperButtonWrapper,
  NaxionWhitePaperContent,
  NaxionWhitePaperImage,
  NaxionWhitePaperSection,
  NaxionWhitePaperTitle,
  NaxionWhitePaperTitleWrapper,
  NaxionWrapper,
  NexionNetworkCardText,
  NexionNetworkCardTitle,
  MobileNaxionNetworkContent,
  NaxionNetworkCardBlur,
  NaxionHeaderVideoWrapper,
} from "./Naxion.styles";
import Layout from "../common/Layout";
import SectionTitle from "../common/SectionTitle";
import Button from "../common/Button";
import YouTube from "react-youtube";

const Naxion = () => {
  return (
    <DefaultTemplate>
      <NaxionWrapper>
        <NaxionHeadWrapper>
          <NaxionHeaderVideoWrapper>
            <YouTube
              videoId="9mbFHuqoHew"
              onEnd={(event) => {
                event.target.seekTo(0);
              }}
              opts={{
                playerVars: {
                  autoplay: 1,
                  rel: 0,
                  modestbranding: 1,
                  mute: 1,
                  controls: 0,
                  loop: 1,
                  showinfo: 0,
                },
              }}
            />
          </NaxionHeaderVideoWrapper>
        </NaxionHeadWrapper>
        <NaxionIntroductionSection>
          <Layout.Wrapper>
            <SectionTitle color="outline-white">
              <span>ABOUT NAXION</span>
              INTRODUCTION
            </SectionTitle>
            <NaxionIntroductionContent>
              <NaxionIntroductionImage src="/assets/images/naxion/naxion-introduction-image.png" />
              <NaxionIntroductionText>
                ​Naxion is a blockchain network tech and WEB 3.0 philosophy for
                building services, holding assets, transacting and communicating
                without being controlled by a central authority. We believe the
                network for WEB3.0 should have optimal decentralization,
                stability, higher transaction speed, and lower gas-fee.
                Everything you dream in WEB3.0 comes true with Naxion.
              </NaxionIntroductionText>
            </NaxionIntroductionContent>
            <NaxionIntroductionButtonWrapper>
              <Button
                color="fill-white"
                size="small"
                onClick={() => {
                  window.open("https://www.naxion.net");
                }}
              >
                LEARN MORE
              </Button>
            </NaxionIntroductionButtonWrapper>
          </Layout.Wrapper>
        </NaxionIntroductionSection>
        <NaxionSection>
          <Layout.Wrapper>
            <SectionTitle>Naxion Network Features</SectionTitle>
            <NaxionNetworkContent>
              <NaxionNetworkContentColumn>
                <NaxionNetworkCardBlur
                  style={{ left: -197, top: 141 }}
                  src="/assets/images/naxion/naxion-card-blur-01.png"
                />
                <NaxionNetworkCardBlur
                  style={{ left: -243, top: 1004 }}
                  src="/assets/images/naxion/naxion-card-blur-02.png"
                />
                <NaxionNetworkCardBlur
                  style={{ left: -113, top: 1400 }}
                  src="/assets/images/naxion/naxion-card-blur-03.png"
                />
                <NaxionNetworkCard className="gray">
                  <NexionNetworkCardTitle>
                    Fully Decentralized Public
                    <br /> Layer1 Blockchain Mainnet
                  </NexionNetworkCardTitle>
                  <NexionNetworkCardText>
                    There is no central authority or system to control Naxion.
                    With complete decentralization, applications on the NAXION
                    can expand the scale of the process of transaction
                    performance and contributes to the structuralization of
                    permanent data service-based economic incentives as the
                    substitute for the centralized server structure.
                  </NexionNetworkCardText>
                </NaxionNetworkCard>
                <NaxionNetworkCard className="aqua">
                  <NexionNetworkCardTitle>
                    Developed for Multipurpose Service
                  </NexionNetworkCardTitle>
                  <NexionNetworkCardText>
                    NAXION is developed as an appropriate blockchain mainnet for
                    multipurpose use environment. As stated earlier, NAXION is
                    not issued as a digital currency but designed for multiple
                    purposes such as data management of various businesses, DID,
                    financial trades, and making NFT. This can be applied for
                    certain business purposes that use data.
                  </NexionNetworkCardText>
                </NaxionNetworkCard>
                <NaxionNetworkCard className="gray">
                  <NexionNetworkCardTitle>
                    Convenient and Simple Network Accessibility
                  </NexionNetworkCardTitle>
                  <NexionNetworkCardText>
                    NAXION is developed with users’ convenience and simple
                    accessibility as the top priority. Equipped with the
                    globally most widely-used blockchain networks, interfaces,
                    link specifications, etc. that are familiar to blockchain
                    developers, the network is made so that the developers can
                    access and apply it easily and fast.
                  </NexionNetworkCardText>
                </NaxionNetworkCard>
                <NaxionNetworkCard className="white">
                  <NexionNetworkCardTitle>
                    Very Low Transaction Gas-fee
                  </NexionNetworkCardTitle>
                  <NexionNetworkCardText>
                    NAXION is designed to require very low network cost for
                    block consensus on actual uses. The issue of losing utility
                    function due to an increase in network cost causes issues
                    with the practical vitality of the blockchain network.
                    Projects based on conventional blockchain networks are
                    failing due to excessive network costs.
                  </NexionNetworkCardText>
                </NaxionNetworkCard>
              </NaxionNetworkContentColumn>
              <NaxionNetworkContentColumn>
                <NaxionNetworkCardBlur
                  style={{ right: -267, top: 600 }}
                  src="/assets/images/naxion/naxion-card-blur-04.png"
                />
                <NaxionNetworkCard className="gray">
                  <NexionNetworkCardTitle>
                    Designed for Massive Service
                  </NexionNetworkCardTitle>
                  <NexionNetworkCardText>
                    NAXION has applied technologies that are thought to be
                    useful for massive distributed applications, with its main
                    focus points on very low network cost, short development
                    period, security for used applications, and situations where
                    efficient interactions with other applications are
                    important.
                  </NexionNetworkCardText>
                </NaxionNetworkCard>
                <NaxionNetworkCard className="white">
                  <NexionNetworkCardTitle>
                    Aimed for the High-performance Service Platform
                  </NexionNetworkCardTitle>
                  <NexionNetworkCardText>
                    NAXION aimed to do more work more effectively compared to
                    Bitcoin, Ethereum etc. NAXION has higher efficiency,
                    excellent encryption, decentralization, stability and low
                    gas-fee.
                  </NexionNetworkCardText>
                </NaxionNetworkCard>
                <NaxionNetworkCard className="aqua">
                  <NexionNetworkCardTitle>
                    Secure Node Applied
                  </NexionNetworkCardTitle>
                  <NexionNetworkCardText>
                    For the connection between the nodes, the security, and
                    verification method are reinforced to reinforce the security
                    of the blockchain, and unverified nodes do not work
                    publically. This is to block the access of ASICS miners that
                    aims only for digital currencies and secure the network
                    speed and stability for nodes that are used for actual
                    businesses.
                  </NexionNetworkCardText>
                </NaxionNetworkCard>
                <NaxionNetworkCard className="gray">
                  <NexionNetworkCardTitle>
                    Eco-friendly System with maintenance resources
                  </NexionNetworkCardTitle>
                  <NexionNetworkCardText>
                    The resource used for the NAXION network operation process
                    is minimized with the utmost effort to lower the
                    environmental effect as much as possible. Efficient work
                    method from the ground level of NAXION minimized consensus
                    method and operation design centered on the actual users
                    allowed it to overcome excessive power consumption and low
                    data process speed that can be seen from competitors.
                  </NexionNetworkCardText>
                </NaxionNetworkCard>
              </NaxionNetworkContentColumn>
            </NaxionNetworkContent>
            {/* INFO : 모바일 콘텐츠 */}
            <MobileNaxionNetworkContent>
              <NaxionNetworkCard className="gray">
                <NexionNetworkCardTitle>
                  Fully Decentralized Public Layer1 Blockchain Mainnet
                </NexionNetworkCardTitle>
                <NexionNetworkCardText>
                  There is no central authority or system to control Naxion.
                  With complete decentralization, applications on the NAXION can
                  expand the scale of the process of transaction performance and
                  contributes to the structuralization of permanent data
                  service-based economic incentives as the substitute for the
                  centralized server structure.
                </NexionNetworkCardText>
              </NaxionNetworkCard>
              <NaxionNetworkCard className="gray">
                <NexionNetworkCardTitle>
                  Designed for Massive Service
                </NexionNetworkCardTitle>
                <NexionNetworkCardText>
                  NAXION has applied technologies that are thought to be useful
                  for massive distributed applications, with its main focus
                  points on very low network cost, short development period,
                  security for used applications, and situations where efficient
                  interactions with other applications are important.
                </NexionNetworkCardText>
              </NaxionNetworkCard>
              <NaxionNetworkCard className="aqua">
                <NexionNetworkCardTitle>
                  Developed for Multipurpose Service
                </NexionNetworkCardTitle>
                <NexionNetworkCardText>
                  NAXION is developed as an appropriate blockchain mainnet for
                  multipurpose use environment. As stated earlier, NAXION is not
                  issued as a digital currency but designed for multiple
                  purposes such as data management of various businesses, DID,
                  financial trades, and making NFT. This can be applied for
                  certain business purposes that use data.
                </NexionNetworkCardText>
              </NaxionNetworkCard>
              <NaxionNetworkCard className="white">
                <NexionNetworkCardTitle>
                  Aimed for the High-performance Service Platform
                </NexionNetworkCardTitle>
                <NexionNetworkCardText>
                  NAXION aimed to do more work more effectively compared to
                  Bitcoin, Ethereum etc. NAXION has higher efficiency, excellent
                  encryption, decentralization, stability and low gas-fee.
                </NexionNetworkCardText>
              </NaxionNetworkCard>
              <NaxionNetworkCard className="gray">
                <NexionNetworkCardTitle>
                  Convenient and Simple Network Accessibility
                </NexionNetworkCardTitle>
                <NexionNetworkCardText>
                  NAXION is developed with users’ convenience and simple
                  accessibility as the top priority. Equipped with the globally
                  most widely-used blockchain networks, interfaces, link
                  specifications, etc. that are familiar to blockchain
                  developers, the network is made so that the developers can
                  access and apply it easily and fast.
                </NexionNetworkCardText>
              </NaxionNetworkCard>
              <NaxionNetworkCard className="aqua">
                <NexionNetworkCardTitle>
                  Secure Node Applied
                </NexionNetworkCardTitle>
                <NexionNetworkCardText>
                  For the connection between the nodes, the security, and
                  verification method are reinforced to reinforce the security
                  of the blockchain, and unverified nodes do not work
                  publically. This is to block the access of ASICS miners that
                  aims only for digital currencies and secure the network speed
                  and stability for nodes that are used for actual businesses.
                </NexionNetworkCardText>
              </NaxionNetworkCard>
              <NaxionNetworkCard className="white">
                <NexionNetworkCardTitle>
                  Very Low Transaction Gas-fee
                </NexionNetworkCardTitle>
                <NexionNetworkCardText>
                  NAXION is designed to require very low network cost for block
                  consensus on actual uses. The issue of losing utility function
                  due to an increase in network cost causes issues with the
                  practical vitality of the blockchain network. Projects based
                  on conventional blockchain networks are failing due to
                  excessive network costs.
                </NexionNetworkCardText>
              </NaxionNetworkCard>
              <NaxionNetworkCard className="gray">
                <NexionNetworkCardTitle>
                  Eco-friendly System with maintenance resources
                </NexionNetworkCardTitle>
                <NexionNetworkCardText>
                  The resource used for the NAXION network operation process is
                  minimized with the utmost effort to lower the environmental
                  effect as much as possible. Efficient work method from the
                  ground level of NAXION minimized consensus method and
                  operation design centered on the actual users allowed it to
                  overcome excessive power consumption and low data process
                  speed that can be seen from competitors.
                </NexionNetworkCardText>
              </NaxionNetworkCard>
            </MobileNaxionNetworkContent>
          </Layout.Wrapper>
        </NaxionSection>
        <NaxionWhitePaperSection>
          <Layout.Wrapper>
            <NaxionWhitePaperContent>
              <NaxionWhitePaperTitleWrapper>
                <NaxionWhitePaperTitle>
                  Naxion White Paper
                </NaxionWhitePaperTitle>
                <NaxionWhitePaperImage src="/assets/images/naxion/naxion-white-paper-image.png" />
              </NaxionWhitePaperTitleWrapper>
              <NaxionWhitePaperButtonWrapper>
                <NaxionWhitePaperButton
                  onClick={() => {
                    window.open(
                      "https://www.naxion.net/wp-content/uploads/2023/11/NAXION_Blockchain_Mainnet_Service_Roadmap_v5.5_eng.pdf"
                    );
                  }}
                >
                  ENG
                </NaxionWhitePaperButton>
                <NaxionWhitePaperButton
                  onClick={() => {
                    window.open(
                      "https://www.naxion.net/wp-content/uploads/2023/11/NAXION_Blockchain_Mainnet_Service_Roadmap_v5.5_kor.pdf"
                    );
                  }}
                >
                  KOR
                </NaxionWhitePaperButton>
                <NaxionWhitePaperButton
                  onClick={() => {
                    window.open(
                      "https://www.naxion.net/wp-content/uploads/2023/11/NAXION_Blockchain_Mainnet_Service_Roadmap_v5.5_chn.pdf"
                    );
                  }}
                >
                  CHN
                </NaxionWhitePaperButton>
              </NaxionWhitePaperButtonWrapper>
            </NaxionWhitePaperContent>
          </Layout.Wrapper>
        </NaxionWhitePaperSection>
      </NaxionWrapper>
    </DefaultTemplate>
  );
};

export default Naxion;
