import styled, { css } from "styled-components";
import IconButton from "../IconButton";

export type StyledArrowButtonProps = {
  color?: "black" | "white";
  direction?: "left" | "right";
  size: 24 | 36;
};
export const StyledArrowButton = styled(IconButton)<StyledArrowButtonProps>`
  display: flex;
  align-items: center;
  border-radius: 50%;
  transition: background 0.15s;
  ${({ theme, color }) => {
    switch (color) {
      case "white":
        return css`
          background: ${theme.color.white};
          & path {
            stroke: ${theme.color.black};
          }

          &:hover {
            background: ${theme.color.black};

            & path {
              stroke: ${theme.color.aqua[500]};
            }
          }
        `;
      case "black":
      default:
        return css`
          background: ${theme.color.black};

          &:hover {
            background: ${theme.color.aqua[500]};
          }
        `;
    }
  }}
  ${({ direction }) => {
    switch (direction) {
      case "left":
        return css`
          justify-content: flex-end;
        `;
      case "right":
      default:
        return css`
          justify-content: flex-start;
        `;
    }
  }}
  ${({ size }) => {
    switch (size) {
      case 36:
        return css`
          & svg {
            width: 17px;
          }
        `;
      case 24:
      default:
        return css`
          & svg {
            width: 14px;
          }
        `;
    }
  }}
`;
