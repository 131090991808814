import media from "@/theme/media";
import styled, { css } from "styled-components";

const Container = styled.div`
  padding: 0 16px;
`;

const Wrapper = styled.div<{ maxWidth?: number }>`
  max-width: ${({ maxWidth = 1280 }) => `${maxWidth}px`};
  width: 100%;
  margin: 0 auto;
`;

const Section = styled.section`
  padding: 160px 16px;

  ${media.mobile`
    ${css`
      padding: 70px 16px;
    `}
  `}
`;

const Layout = {
  Container,
  Wrapper,
  Section,
};

export default Layout;
