import DefaultTemplate from "@/templates/DefaultTemplate";
import styled, { css } from "styled-components";
import Typography from "../common/Typography";
import Layout from "../common/Layout";
import { convertObjectToCss } from "@/utils/common/styles";
import media from "@/theme/media";

export const ContactUsWrapper = styled.div``;

export const ContactUsHeadWrapper = styled(DefaultTemplate.HeaderOffset).attrs({
  as: "section",
})`
  ${({ theme }) => css`
    position: relative;
    background: ${theme.color.aqua[500]};
  `}
`;

export const ContactUsHeadContent = styled.div`
  text-align: center;
  padding: 76px 0 60px;

  ${media.mobile`
    ${css`
      padding: 33px 0;
    `}
  `}
`;

export const ContactUsHeadSymbol = styled.img`
  ${media.mobile`
    ${css`
      width: 43px;
    `}
  `}
`;

export const ContactUsHeadTitle = styled(Typography).attrs({
  variant: "subtitle2",
})`
  ${({ theme }) => css`
    color: ${theme.color.white};

    ${media.mobile`
    ${css`
      ${convertObjectToCss(theme.typography.body7)};
      font-weight: ${theme.font.fontWeight.bold};
    `}
    `}
  `}
`;

export const ContactUsSection = styled(Layout.Section)`
  padding: 72px 16px 104px;

  ${media.mobile`
    ${css`
      padding: 36px 16px;
    `}
  `}
`;

export const ContactUsFormWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 40px;

  ${media.mobile`
    ${css`
      flex-direction: column;
    `}
  `}
`;

export const ContactUsFormColumn = styled.div`
  width: 100%;
  max-width: 630px;

  ${media.mobile`
    ${css`
      max-width: unset;
    `}
  `}
`;

export const ContactUsFormRow = styled.div`
  &:not(:last-child) {
    margin-bottom: 40px;
  }

  ${media.mobile`
    ${css`
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    `}
  `}
`;

export const ContactUsFormButtonWrapper = styled.div`
  text-align: center;
`;

export const ContactUsFormLabel = styled(Typography).attrs({
  variant: "body9",
  as: "label",
})`
  ${({ theme }) => css`
    color: ${theme.color.gray[600]};
    display: inline-block;
    margin-bottom: 8px;
    &:before {
      content: "*";
      color: ${theme.color.aqua[500]};
      display: inline-block;
      margin-right: 4px;
    }

    ${media.mobile`
    ${css`
      margin-bottom: 4px;

      ${convertObjectToCss(theme.typography.body10)};
    `}
  `}
  `}
`;

export const ContactUsFormInput = styled.input`
  ${({ theme }) => css`
    color: ${theme.color.gray[700]};
    width: 100%;
    border: 0;
    outline: 0;
    padding: 18px 20px;
    background: ${theme.color.gray[100]};
    border-radius: 10px;

    ${convertObjectToCss(theme.typography.body9)};

    &::placeholder {
      color: ${theme.color.gray[500]};
      ${convertObjectToCss(theme.typography.body9)};
    }

    ${media.mobile`
      ${css`
        padding: 14px 10px;
        ${convertObjectToCss(theme.typography.body10)};
      `}
    `}
  `}
`;
export const ContactUsFormTextArea = styled.textarea`
  resize: none;
  width: 100%;

  ${({ theme }) => css`
    border: 0;
    outline: 0;
    padding: 18px 20px;
    background: ${theme.color.gray[100]};
    border-radius: 10px;
    min-height: 120px;

    ${convertObjectToCss(theme.typography.body9)};

    &::placeholder {
      color: ${theme.color.gray[500]};
      ${convertObjectToCss(theme.typography.body9)};
    }

    ${media.mobile`
      ${css`
        padding: 14px 10px;
        min-height: 70px;
        ${convertObjectToCss(theme.typography.body10)};
      `}
    `}
  `}
`;
export const ContactUsCheckWrapper = styled.div`
  display: flex;
  gap: 12px;
`;
export const ContactUsCheckText = styled(Typography).attrs({
  variant: "body9",
})`
  ${({ theme }) => css`
    color: ${theme.color.aqua[500]};
  `}
`;

export const ContactUsInfoColumn = styled.div`
  max-width: 456px;
  width: 100%;
  text-align: center;

  ${media.mobile`
    ${css`
      max-width: unset;
    `}
  `}
`;

export const ContactUsInfoText = styled(Typography).attrs({
  variant: "body8",
})`
  text-align: left;
  margin-bottom: 100px;
`;

export const ContactUsInfoImage = styled.img`
  ${media.mobile`
    ${css`
      max-width: 181px;
    `}
  `}
`;
