import media from "@/theme/media";
import styled, { css } from "styled-components";

export const DefaultTemplateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const HeaderOffset = styled.div`
  padding-top: 70px;

  ${media.mobile`
      ${css`
        padding-top: 56px;
      `}
    `}
`;
