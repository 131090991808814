import React from "react";
import useMediaQuery from "./useMediaQuery";
import { deviceSizes } from "@/theme/media";

const useMobile = () => {
  const isMobile = useMediaQuery(`(max-width:${deviceSizes.mobile}px)`);

  const mobileStyle = (styles: React.CSSProperties): React.CSSProperties => {
    if (!isMobile) return {};
    return styles;
  };

  return {
    isMobile,
    mobileStyle,
  };
};

export default useMobile;
