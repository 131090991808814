import IconButton from "@/components/common/IconButton";
import media from "@/theme/media";
import styled, { css } from "styled-components";

export const ModalWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
`;

export const ModalDimmed = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  ${({ theme }) => css`
    opacity: 0.75;
    background: ${theme.color.black};
  `}
`;

export const ModalImageWrapper = styled.div`
  position: fixed;

  width: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  box-shadow: 0 4px 20px #0000001a;
`;
export const ModalImage = styled.img`
  width: 100%;
  height: 100%;
  max-height: 100vh;
  max-width: 100vw;
  padding: 20px;
  object-fit: contain;
`;

export const ModalCloseButton = styled(IconButton).attrs({
  size: 40,
  icon: "Close",
})`
  ${({ theme }) => css`
    position: absolute;
    right: 30px;
    top: 30px;
    background: #11111180;
    border-radius: 24px;

    display: flex;
    align-items: center;
    justify-content: center;
    & svg {
      width: 24px;
      height: 24px;
    }
    & path {
      stroke: ${theme.color.white};
    }

    ${media.mobile`
    ${css`
      right: 16px;
      top: 16px;

      width: 32px !important;
      height: 32px !important;

      & svg {
        width: 20px;
        height: 20px;
      }
    `}
    `}
  `}
`;
