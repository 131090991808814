import DefaultTemplate from "@/templates/DefaultTemplate";
import {
  UseCasesHeadContentContainer,
  UseCasesHeadContentText,
  UseCasesHeadContentTitle,
  UseCasesHeadContentWrapper,
  UseCasesHeadDimmed,
  UseCasesHeadWrapper,
  UseCasesInfoContainer,
  UseCasesInfoContent,
  UseCasesInfoImage,
  UseCasesInfoImageWrapper,
  UseCasesInfoText,
  UseCasesInfoTitle,
  UseCasesInfoWrapper,
  UseCasesWebContent,
  UseCasesWebDescription,
  UseCasesWebSection,
  UseCasesWrapper,
} from "./UseCases.styles";
import Button from "../common/Button";
import Layout from "../common/Layout";
import SectionTitle from "../common/SectionTitle";
import SlideList from "./SlideList";
import { useState } from "react";
import ModalContainer from "@/containers/ModalContainer";
import ZoomImageModal from "./ZoomImageModal";

const UseCases = () => {
  const [openModal, setOpenModal] = useState<string | null>(null);

  const useWebGamesList = [
    {
      imgSrc: "/assets/images/use-cases/use-cases-slide-01.jpg",
    },
    {
      imgSrc: "/assets/images/use-cases/use-cases-slide-02.png",
    },
    {
      imgSrc: "/assets/images/use-cases/use-cases-slide-03.png",
    },
  ];
  return (
    <DefaultTemplate>
      <UseCasesWrapper>
        <UseCasesHeadWrapper>
          <UseCasesHeadDimmed />
          <UseCasesHeadContentContainer>
            <UseCasesHeadContentWrapper>
              <UseCasesHeadContentTitle>
                Reinforcement of business Capacity and innovation!
              </UseCasesHeadContentTitle>
              <UseCasesHeadContentText>
                Join us with the SIZN3 Token Economy Platform!
              </UseCasesHeadContentText>
              <Button size="large" color="outline-white" onClick={() => window.open("https://sizn3.com/dashboard", "_blank")}>
                TRY SIZN3
              </Button>
            </UseCasesHeadContentWrapper>
          </UseCasesHeadContentContainer>
        </UseCasesHeadWrapper>
        <UseCasesInfoContainer>
          <UseCasesInfoWrapper>
            <UseCasesInfoImageWrapper>
              <UseCasesInfoImage src="/assets/images/use-cases/use-cases-info-ublox.png" />
            </UseCasesInfoImageWrapper>
            <UseCasesInfoContent>
              <UseCasesInfoTitle>UBlox: NFT Marketplace</UseCasesInfoTitle>
              <UseCasesInfoText>
                UBlox is the Real Time On-Chain NFT Marketplace. Discover,
                Collect, and Sell Your own NFTs!
              </UseCasesInfoText>
              <Button
                size="small"
                color="fill-aqua-white-500"
                onClick={() => {
                  window.open("https://www.ublox.io");
                }}
              >
                LEARN MORE
              </Button>
            </UseCasesInfoContent>
          </UseCasesInfoWrapper>
        </UseCasesInfoContainer>
        <UseCasesInfoContainer className="gray-bg">
          <UseCasesInfoWrapper reverse>
            <UseCasesInfoImageWrapper>
              <UseCasesInfoImage src="/assets/images/use-cases/use-cases-info-naxion.png" />
            </UseCasesInfoImageWrapper>
            <UseCasesInfoContent>
              <UseCasesInfoTitle>Naxion Scanner</UseCasesInfoTitle>
              <UseCasesInfoText>
                Naxion Block Scanner is a tool for inspecting and analyzing EVM
                based blockchains.
              </UseCasesInfoText>
              <Button
                size="small"
                color="fill-aqua-white-500"
                onClick={() => {
                  window.open("https://blockscan.naxion.info");
                }}
              >
                LEARN MORE
              </Button>
            </UseCasesInfoContent>
          </UseCasesInfoWrapper>
        </UseCasesInfoContainer>
        <UseCasesInfoContainer>
          <UseCasesInfoWrapper>
            <UseCasesInfoImageWrapper>
              <UseCasesInfoImageWrapper>
                <UseCasesInfoImage
                  style={{ maxWidth: 610 }}
                  src="/assets/images/use-cases/use-cases-info-persona.png"
                />
              </UseCasesInfoImageWrapper>
            </UseCasesInfoImageWrapper>
            <UseCasesInfoContent>
              <UseCasesInfoTitle>Persona: Crypto Wallet</UseCasesInfoTitle>
              <UseCasesInfoText>
                Store all of your crypto and NFTs in one place & Protect your
                digital assets with industry-leading security!
              </UseCasesInfoText>
              <Button
                size="small"
                color="fill-aqua-white-500"
                onClick={() => {
                  window.open("https://persona.nverse.ai");
                }}
              >
                LEARN MORE
              </Button>
            </UseCasesInfoContent>
          </UseCasesInfoWrapper>
        </UseCasesInfoContainer>
        <UseCasesWebSection>
          <Layout.Wrapper maxWidth={1280}>
            <SectionTitle color="outline-white">WEB3.0 GAMES</SectionTitle>
            <UseCasesWebDescription>
              Game developers can ensure a rewarding gameplay experience for
              players by maintaining a balance through the formulation of the
              game economy, adjusting token rewards, costs, and availability.
            </UseCasesWebDescription>
            <UseCasesWebContent>
              <SlideList
                onClickImage={(index) => {
                  setOpenModal(index);
                }}
                list={useWebGamesList}
              />
            </UseCasesWebContent>
            {openModal && (
              <ZoomImageModal
                imageSrc={openModal}
                onClose={() => {
                  setOpenModal(null);
                }}
              />
            )}
          </Layout.Wrapper>
        </UseCasesWebSection>
      </UseCasesWrapper>
    </DefaultTemplate>
  );
};

export default UseCases;
