import { PropsWithChildren } from "react";
import { DefaultTemplateWrapper, HeaderOffset } from "./DefaultTemplate.styles";
import Footer from "./Footer";
import Header from "./Header";

const DefaultTemplate = ({ children }: PropsWithChildren) => {
  return (
    <DefaultTemplateWrapper>
      <Header />
      <main>{children}</main>
      <Footer />
    </DefaultTemplateWrapper>
  );
};

DefaultTemplate.HeaderOffset = HeaderOffset;
export default DefaultTemplate;
