import { useState } from "react";
import Icon from "../Icon";

const CheckBox = (props: any) => {
  const [checked, setChecked] = useState(false);

  return (
    <label style={{ cursor: "pointer" }}>
      <input
        type="checkbox"
        hidden
        onChange={(event) => {
          props.propFunction(event.target.checked)
          setChecked(event.target.checked);
        }}
      />
      {checked ? <Icon icon="SquareCheckOn" /> : <Icon icon="SquareCheckOff" color="white"/>}
    </label>
  );
};

export default CheckBox;
