import { ButtonHTMLAttributes, forwardRef } from "react";
import Icon, { IconType } from "../Icon";

type IconButtonProps = {
  size: number;
  icon: IconType;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ size, icon, ...buttonProps }, buttonRef) => {
    return (
      <button
        style={{
          width: size,
          height: size,
        }}
        ref={buttonRef}
        {...buttonProps}
      >
        <Icon icon={icon} />
      </button>
    );
  }
);

export default IconButton;
