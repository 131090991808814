const fontWeight = {
  black: 900,
  extraBold: 800,
  bold: 700,
  semiBold: 600,
  medium: 500,
  regular: 400,
  light: 300,
  extraLight: 200,
  thin: 100,
};

const GlobalTheme = {
  color: {
    gray: {
      50: "#FAFAFA",
      100: "#F5F5F5",
      200: "#EEEEEE",
      300: "#E0E0E0",
      400: "#CCCCCC",
      500: "#AAAAAA",
      600: "#616161",
      700: "#424242",
      800: "#222222",
      900: "#111111",
    },
    aqua: {
      500: "#10BEBF",
    },
    white: "#FFFFFF",
    black: "#111111",
    shadow: {
      0: "rgba(0, 0, 0, 0.1)",
    },
  },
  font: {
    fontWeight,
  },
  breakpoint: {
    mobile: "375px",
  },
  typography: {
    subtitle1: {
      fontSize: "48px",
      fontWeight: fontWeight.extraBold,
    },
    subtitle2: {
      fontSize: "40px",
      fontWeight: fontWeight.semiBold,
    },
    subtitle3: {
      fontSize: "34px",
      fontWeight: fontWeight.bold,
    },
    body1: {
      fontSize: "40px",
      fontWeight: fontWeight.bold,
    },
    body2: {
      fontSize: "40px",
      fontWeight: fontWeight.light,
    },
    body3: {
      fontSize: "30px",
      fontWeight: fontWeight.semiBold,
    },
    body4: {
      fontSize: "27px",
      fontWeight: fontWeight.semiBold,
    },
    body5: {
      fontSize: "24px",
      fontWeight: fontWeight.semiBold,
    },
    body6: {
      fontSize: "24px",
      fontWeight: fontWeight.regular,
    },
    body7: {
      fontSize: "21px",
      fontWeight: fontWeight.regular,
    },
    body8: {
      fontSize: "18px",
      fontWeight: fontWeight.regular,
    },
    body9: {
      fontSize: "16px",
      fontWeight: fontWeight.regular,
    },
    body10: {
      fontSize: "14px",
      fontWeight: fontWeight.regular,
    },
    button1: {
      fontSize: "38px",
      fontWeight: fontWeight.light,
    },
    button2: {
      fontSize: "30px",
      fontWeight: fontWeight.light,
    },
    button3: {
      fontSize: "21px",
      fontWeight: fontWeight.light,
    },
    button4: {
      fontSize: "18px",
      fontWeight: fontWeight.light,
    },
  },
};

export default GlobalTheme;
