import {
  StyledArrowButton,
  StyledArrowButtonProps,
} from "./ArrowButton.styles";

type ArrowButtonProps = StyledArrowButtonProps & {
  size: 24 | 36;
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
};
const ArrowButton = ({
  color,
  direction,
  size,
  disabled,
  className = "arrow-button",
  onClick,
}: ArrowButtonProps) => {
  return (
    <StyledArrowButton
      icon={direction === "left" ? "ArrowLeft" : "ArrowRight"}
      size={size}
      color={color}
      direction={direction}
      disabled={disabled}
      className={className}
      onClick={onClick}
    />
  );
};

export default ArrowButton;
