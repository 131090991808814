import styled, { css } from "styled-components";
import Typography from "../Typography";
import media from "@/theme/media";
import { convertObjectToCss } from "@/utils/common/styles";

type SectionTitleProps = {
  color?: "fill-black" | "outline-white";
};
const SectionTitle = styled(Typography).attrs({
  variant: "subtitle2",
})<SectionTitleProps>`
  ${({ theme, color = "fill-black" }) => css`
    display: inline-block;

    padding: 26px 44px;
    border-radius: 164px;

    & span {
      display: block;
      color: ${theme.color.aqua[500]};
      font-size: 18px;
      font-weight: 400;
    }

    ${() => {
      switch (color) {
        case "fill-black":
          return css`
            background: ${theme.color.black};
            color: ${theme.color.white};
          `;
        case "outline-white":
          return css`
            border: 2px solid ${theme.color.white};
            color: ${theme.color.white};
          `;
      }
    }}

    ${media.mobile`
    ${css`
      padding: 13px 22px;
      ${convertObjectToCss(theme.typography.body7)};
      font-weight: ${theme.font.fontWeight.bold};

      & span {
        font-size: 14px;
      }
    `}
    `}
  `}
`;

export default SectionTitle;
