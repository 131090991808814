import { PropsWithChildren, useEffect } from "react";
import ReactDOM from "react-dom";

const ModalContainer = ({ children }: PropsWithChildren) => {
  const modalRoot = document.querySelector("#modal-root")!;

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);
  return ReactDOM.createPortal(<>{children}</>, modalRoot);
};

export default ModalContainer;
