import ModalContainer from "@/containers/ModalContainer";
import {
  ModalCloseButton,
  ModalDimmed,
  ModalImage,
  ModalImageWrapper,
  ModalWrapper,
} from "./ZoomImageModal.styles";

type ZoomImageModalProps = {
  imageSrc: string;
  onClose?: () => void;
};
const ZoomImageModal = ({ imageSrc, onClose }: ZoomImageModalProps) => {
  return (
    <ModalContainer>
      <ModalWrapper>
        <ModalDimmed
          onClick={() => {
            onClose?.();
          }}
        />
        <ModalImageWrapper>
          <ModalImage src={imageSrc} />
        </ModalImageWrapper>
        <ModalCloseButton
          onClick={() => {
            onClose?.();
          }}
        />
      </ModalWrapper>
    </ModalContainer>
  );
};

export default ZoomImageModal;
